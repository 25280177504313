import ajax from "./ajax";

export function getContentsByType(type) {
  return ajax("/contents?page=1&per-page=1000&sort=display_order&ContentsSearch[type]=" + type);
}

export function getContentDetails(id) {
  return ajax("/contents/" + id);
}

export function updateContentsById(id, data) {
  return ajax(`/contents/${id}`, { method: "PUT", data });
}

export function createContent(data) {
  return ajax(`/contents`, { method: "POST", data });
}

export function getContentsByCustomerId(type, id) {
  return ajax(`/contents?ContentsSearch[type]=${type}&ContentsSearch[customer_id]=${id}`);
}

export function getContentsByParam(params) {
  return ajax(`/contents?${params}`)
}

export function deleteContent(id) {
  return ajax(`/contents/${id}`, { method: "DELETE" });
}
