/** Add Route Component */
//Home Section

import ComingSoon from "../helpers/ComingSoon";
import AboutUs from "../pages/Company/AboutUs/AboutUs";
import Services from "../pages/Company/Services/Services";
import ResetPassword from "../pages/ExtraPages/ResetPassword";
import SignIn from "../pages/ExtraPages/SignIn";
import SignOut from "../pages/ExtraPages/SignOut";
import SignUp from "../pages/ExtraPages/SignUp";
import Faqs from "../pages/Company/Faqs/Faqs";
import PrivacyAndPolicy from "../pages/Company/PrivacyAndPolicy/PrivacyAndPolicy";
import Pricing from "../pages/Company/Pricing/Pricing";
import Team from "../pages/Company/Team/Team";
import Contact from "../pages/Contact/Contact";
// import MyComingSoon from "../helpers/ComingSoon";

// -------------------  Employer  ------------------- //

import EmpMyProfile from "../pages/Account/Employer/MyProfile/MyProfile";
import EmpManageCompanies from "../pages/Account/Employer/ManageCompanies";
// import EmpJobListing from "../pages/Account/Employer/ManageCompanies/JobListing";
import AddNewCompany from "../pages/Account/Employer/ManageCompanies/AddNewCompany";
import EditCompany from "../pages/Account/Employer/ManageCompanies/EditCompany";
import EmpManageJobs from "../pages/Account/Employer/ManageJobs";
import EmpManageJobApplications from "../pages/Account/Employer/ManageJobApplications";
import EmpBookMarkCandidates from "../pages/Account/Employer/BookMarkCandidates";
import EmpDashBoard from "../pages/Account/Employer/DashBoard";
import CompanyDetails from "../pages/Account/Employer/CompanyDetails/CompanyDetails";

import AddNewJob from "../pages/Account/Employer/ManageJobs/AddNewJob";
import EditJobsPosts from "../pages/Account/Employer/ManageJobs/EditJobsPosts";

// -------------------  Candidate  ------------------- //

import CandiMyProfile from "../pages/Account/Candidate/MyProfile/MyProfile";
import CandiManageJobApplications from "../pages/Account/Candidate/ManageJobApplications";
import CandiManageBookmarkedJobs from "../pages/Account/Candidate/ManageBookmarkJobs/ManageBookmarkJobs";
import CandiDashBoard from "../pages/Account/Candidate/DashBoard";

import JobsCategories from "../pages/Jobs/JobsCategories/JobsCategories";
import JobDetails from "../pages/Jobs/JobDetails/JobDetails";

import CmsPage from "../pages/CmsPage";
import JobListing from "../pages/Jobs/JobListing";
import CandidateProfileDetails from "../pages/CandidateProfileDetails";

//________________________  Temp ___________________________

import CandidateDetails from "../pages/temp/CandidateAndCompany/CandidateDetails/CandidateDetails";
import CompanyList from "../pages/temp/CandidateAndCompany/CompanyList/CompanyList";
// import CandidateGrid from "../pages/temp/CandidateAndCompany/CandidateGrid/CandidateGrid";
import CandidateList from "../pages/CandidateList";
import Notifications from "../pages/Notifications";
import Checkout from "../pages/Checkout";
import Orders from "../pages/Account/Employer/Orders";
import Blogs from "../pages/Blogs/Blogs";
import BlogDetails from "../pages/BlogDetails/BlogDetails";
import PurchasedCandidates from "../pages/Account/Employer/PurchasedCandidates";
import ResumeBuilder from "../pages/ResumeBuilder";

// ############# commonRoutes #############
const commonRoutes = [
  { path: "/faqs", element: <Faqs /> },
  { path: "/privacy-policy", element: <PrivacyAndPolicy /> },
  { path: "/pricing", element: <Pricing /> },
  { path: "/team", element: <Team /> },
  { path: "/services", element: <Services /> },
  { path: "/about-us", element: <AboutUs /> },
  { path: "/contact-us", element: <Contact /> },
  { path: "/blogs", element: <Blogs /> },
  { path: "/blogs/:blogId/:blogTitle", element: <BlogDetails /> },

  // Cms Pages (static pages)
  { path: "/page/:page", element: <CmsPage /> },

  // Filter
  { path: "/jobs", element: <JobListing /> },

  // ------------------- company -------------------
  {
    path: "/company/details/:companyId/:companyName",
    element: <CompanyDetails />,
  },

  // ------------------- Jobs Section -------------------
  { path: "/job/categories", element: <JobsCategories /> },
  { path: "/job/lists", element: <JobListing /> },
  { path: "/job/details/:jobId/:jobTitle", element: <JobDetails /> },

  { path: "/job-details", element: <JobDetails /> },
  // { path: "/jobgrid2", element: <JobGrid2 /> },
  // { path: "/jobgrid", element: <JobGrid /> },
  // { path: "/job-list2", element: <JobList /> },

  // { path: "/job/list", element: <JobList /> },

  // { path : "/candidate-grid", component: CandidateGrid },
  // { path : "/candidate-list", component: CandidateList },
];

// ############# authRoutes #############
const authRoutes = [
  { path: "/comingsoon", element: <ComingSoon /> },
  { path: "/resetpassword", element: <ResetPassword /> },
  { path: "/signout", element: <SignOut /> },
  { path: "/signup", element: <SignUp /> },
  // { path: "/email-verification", element: <VerifyEmail /> },
  { path: "/signin", element: <SignIn /> },
  { path: "/checkout/:planId", element: <Checkout /> },
];

// ############# authProtectedRoutes #############
const authProtectedRoutes = [
  { path: "/notifications", element: <Notifications /> },

  { path: "/candidates", element: <CandidateList /> },

  { path: "/resume-builder", element: <ResumeBuilder /> },

  // ------------------- Employer route start -------------------

  { path: "/employer/profile", element: <EmpMyProfile /> },
  { path: "/employer/company/manage", element: <EmpManageCompanies /> },
  { path: "/employer/company/edit/:companyId", element: <EditCompany /> },
  { path: "/employer/company/add", element: <AddNewCompany /> },

  { path: "/employer/job/manage", element: <EmpManageJobs /> },
  { path: "/employer/job/manage/:companyId", element: <EmpManageJobs /> },
  {
    path: "/employer/job/manage/:companyId/:companyName",
    element: <EmpManageJobs />,
  },
  { path: "/employer/job/update/:jobId", element: <EditJobsPosts /> },
  { path: "/employer/job/add", element: <AddNewJob /> },
  {
    path: "/employer/job/add/:companyId/:companyTitle",
    element: <AddNewJob />,
  },
  {
    path: "/employer/orders",
    element: <Orders />,
  },

  {
    path: "/employer/purchased-candidates",
    element: <PurchasedCandidates />,
  },

  {
    path: "/employer/job/application/manage",
    element: <EmpManageJobApplications />,
  },
  {
    path: "/employer/job/application/manage/:jobId/:jobTitle",
    element: <EmpManageJobApplications />,
  },

  { path: "/employer/bookmark-candidates", element: <EmpBookMarkCandidates /> },
  { path: "/employer/dashboard", element: <EmpDashBoard /> },

  // ------------------- Employer route end -------------------

  // ------------------- Candidate route start -------------------

  { path: "/candidate/profile/view", element: <CandiMyProfile /> },
  { path: "/candidate/profile/update", element: <CandiMyProfile /> },
  { path: "/candidate/profile/change-password", element: <CandiMyProfile /> },
  {
    path: "/candidate/job/application/manage",
    element: <CandiManageJobApplications />,
  },
  {
    path: "/candidate/job/bookmark/manage",
    element: <CandiManageBookmarkedJobs />,
  },
  { path: "/candidate/dashboard", element: <CandiDashBoard /> },

  {
    path: "/candidate/details/:candidateId",
    element: <CandidateProfileDetails />,
  },

  // ------------------- Candidate route end -------------------
];

const temp = [
  // ------------------- Candidate and Company Section -------------------
  { path: "/companylist", element: <CompanyList /> },
  { path: "/candidate-details", element: <CandidateDetails /> },
  // { path: "/candidate-grid", element: <CandidateGrid /> },
  // { path: "/candidate-list", element: <CandidateList /> },
];

export { commonRoutes, authRoutes, authProtectedRoutes, temp };
