import { GET_FAQS_SUCCESS, GET_FAQS_FAILURE } from '../constants/actionTypes';

const INITIAL_STATE = {
  data: [],
  loading: true
};

export default function faqs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FAQS_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case GET_FAQS_FAILURE:
      return { ...state, data: [], loading: false  };
    default:
      return state;
  }
}
