import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import { getJobs } from "../../../api/jobs";
import { CustomSnackbar } from "../../../helpers/CustomSnackbar";
import JobSearchOptions from "./JobSearchOptions";
import JobVacancyListing from "./JobVacancyListing";
import Section from "./Section";
import Sidebar from "./Sidebar";

const JobListing = () => {
  const jobsData = useSelector((state) => state.jobs);
  const jobs = jobsData.data;
  const [loading, setLoading] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    let query = searchParams.get("q");
    let location = searchParams.get("location");
    let category = searchParams.get("category");
    let job_type = searchParams.get("job_type");
    let work_experience = searchParams.get("work_experience");
    let created_within_hours = searchParams.get("created_within_hours");
    let keyword = searchParams.get("keyword");
    let salaryRange = searchParams.get("salary_range");

    let params = [];

    if (query) {
      params.push(`JobsSearch[q]=${query}`);
    }

    if (location) {
      params.push(`JobsSearch[job_location_id]=${location}`);
    }

    if (category) {
      params.push(`JobsSearch[job_category_id]=${category}`);
    }

    if (job_type) {
      params.push(`JobsSearch[job_type]=${job_type}`);
    }

    if (work_experience) {
      params.push(`work_experience=${work_experience}`);
    }
    
    if (created_within_hours) {
      params.push(`created_within_hours=${created_within_hours}`);
    }

    if (keyword) {
      params.push(`JobsSearch[search_keywords]=${keyword}`);
    }

    if (salaryRange) {
      params.push(`JobsSearch[salary_range]=${salaryRange}`);
    }

    params.push('JobsSearch[is_active]=1');

    const newParams = `&${params.join("&")}`;

    if (pathname.includes("/jobs")) {
      setLoading(true);
      getJobs(newParams)
        .then((res) => {
          if (res?.data) {
            setFilteredJobs(res?.data);
          }
        })
        .catch((err) => {
          console.log("err::", err);
          if (err?.response?.data?.message) {
            CustomSnackbar.error(err?.response?.data?.message);
          } else {
            CustomSnackbar.error(err.message);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [pathname, searchParams]);

  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  }, [jobsData]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Job List | Jobseekers Page </title>
      </MetaTags>
      <Section />
      <section className="section">
        <Container>
          <Row>
            <Col lg={9}>
              <div className="me-lg-5">
                <JobSearchOptions />

                {loading || jobsData?.loading ? (
                  <div style={{ textAlign: "center", marginTop: 110 }}>
                    <Spinner size="lg"></Spinner>
                    <p>Loading...</p>
                  </div>
                ) : (
                  <JobVacancyListing
                    jobs={
                      pathname?.includes("/job/lists") ? jobs : filteredJobs
                    }
                  />
                )}
              </div>
            </Col>
            <Sidebar />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobListing;
