import React, { useState } from "react";
import { Collapse } from "reactstrap";
import parse from "html-react-parser";

const AccordionItem = ({ item }) => {
  const [isCollapse, setIsCollapse] = useState(false);
  const toggleFirst = () => setIsCollapse(!isCollapse);

  return (
    <div className='accordion-item mb-4 border-0'>
      <h2 className='accordion-header' id={`faq-${item?.id}`}>
        <button
          className={'accordion-button'}
          onClick={toggleFirst}
          type='button'
        >
          {item?.question}
        </button>
      </h2>
      <Collapse isOpen={isCollapse} id={`faq-${item?.id}`}>
        <div className='accordion-body'>{parse(item?.answer ?? "")}</div>
      </Collapse>
    </div>
  );
};

export default AccordionItem;
