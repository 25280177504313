import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { forgotEmailPassword } from "../../../api/customer";
import resetPasswordImage from "../../../assets/images/auth/reset-password.png";
import WebsiteLogo from "../../../helpers/WebsiteLogo";
import { apiErrorHandler } from "../../../helpers/common";
import { CustomSnackbar } from "../../../helpers/CustomSnackbar";
import VerifyEmail from "../SignUp/VerifyEmail";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openVerifyOtp, setOpenVerifyOtp] = useState(false);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await forgotEmailPassword({ email });
      CustomSnackbar.success(response?.data?.message);
      setOpenVerifyOtp(true);
    } catch (error) {
      apiErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const email = localStorage.getItem("last_logged_in_email");

    if (email) {
      setEmail(email);
    }
  }, [setEmail]);

  return (
    <React.Fragment>
      <div>
        <div className='main-content'>
          <div className='page-content'>
            <MetaTags>
              <title>Reset Password | Jobseekers Page </title>
            </MetaTags>
            <section className='bg-auth'>
              <Container>
                <Row className='justify-content-center'>
                  <Col xl={10} lg={12}>
                    <Card className='auth-box'>
                      <Row className='g-0'>
                        <Col lg={6} className='text-center'>
                          <CardBody className='p-4'>
                            <Link to='/'>
                              <WebsiteLogo height='28' />
                            </Link>
                            <div className='mt-5'>
                              <img
                                src={resetPasswordImage}
                                alt=''
                                className='img-fluid'
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className='auth-content p-5 h-100 text-white w-100'>
                            {openVerifyOtp ? (
                              <VerifyEmail formData={{email}} source="resetPassword" />
                            ) : (
                              <>
                                <div className='text-center mb-4'>
                                  <h5>Reset Password</h5>
                                  <p className='text-white-50'>
                                    Reset your password with Jobseekers Page.
                                  </p>
                                </div>
                                <Form
                                  className='auth-form text-white w-100'
                                  onSubmit={onFormSubmit}
                                >
                                  {/* <div
                                    className='alert alert-warning text-center mb-4'
                                    role='alert'
                                  >
                                    {" "}
                                    Enter your Email and instructions will be sent
                                    to you!{" "}
                                  </div> */}
                                  <div className='mb-4'>
                                    <label
                                      className='form-label'
                                      htmlFor='email'
                                    >
                                      Email
                                    </label>
                                    <Input
                                      type='email'
                                      className='form-control'
                                      id='email'
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      placeholder='Enter username or email'
                                    />
                                  </div>
                                  <div className='mt-3'>
                                    {isLoading ? (
                                      <button
                                        className='btn btn-white w-100 disabled'
                                        type='button'
                                      >
                                        Send OTP <Spinner size='sm' />
                                      </button>
                                    ) : (
                                      <button
                                        type='submit'
                                        className='btn btn-white w-100'
                                      >
                                        Send OTP
                                      </button>
                                    )}
                                  </div>
                                </Form>
                                <div className='mt-5 text-center text-white-50'>
                                  <p>
                                    Remembered It ?{" "}
                                    <Link
                                      to='/signin'
                                      className='fw-medium text-white text-decoration-underline'
                                    >
                                      {" "}
                                      Go to Login{" "}
                                    </Link>
                                  </p>
                                </div>
                              </>
                            )}
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
