import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Col, Container, Row, Input, Label, Spinner } from "reactstrap";
import { contactUs } from "../../api/contacts";
//Import Images
import contactImage from "../../assets/images/contact.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { getSettingByKey, isValidMobile } from "../../helpers/common";
import { CustomSnackbar } from "../../helpers/CustomSnackbar";

const ContactContent = () => {
  const [errors, setErrors] = useState({});
  const initialState = {
    name: "",
    email_address: "",
    contact_number: "",
    subject: "",
    message: "",
    captcha_value: "",
  };
  const [loading, setLoading] = React.useState(false);

  const [formData, setFormData] = useState(initialState);
  const settings = useSelector((state) => state.settings.data);
  const captchaSiteKey = getSettingByKey(
    settings,
    "google_recaptcha_site_key"
  )?.value;
  const recaptchaRef = React.createRef();

  // Company Info
  const companyAddress = getSettingByKey(settings, "company_address")?.value;
  const companyEmailAddress = getSettingByKey(
    settings,
    "company_email_address"
  )?.value;
  const companyContactNumber = getSettingByKey(
    settings,
    "company_contact_number"
  )?.value;
  const contactUsContent = getSettingByKey(
    settings,
    "contact_us_content"
  )?.value;

  function onChange(value) {
    setFormData({ ...formData, captcha_value: value });
  }

  const onChangeValue = (e) => {
    setErrors({ ...errors, [e.target.name]: null });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let err = {};
    setErrors(err);

    if (!formData.name) {
      CustomSnackbar.warning("Name is required!");
      return false;
    }

    if (!formData.email_address) {
      CustomSnackbar.warning("Email address is required!");
      return false;
    }
 
    if (formData.contact_number && !isValidMobile(formData.contact_number)) { 
      CustomSnackbar.warning("Please enter valid mobile number");
      return false;
    }

    if (!formData.subject) {
      CustomSnackbar.warning("Subject is required!");
      return false;
    }


    if (!formData.message) {
      CustomSnackbar.warning("Message is required!");
      return false;
    }

    if (!formData.captcha_value) {
      CustomSnackbar.warning("Please verify captcha");
      return false;
    }

    setLoading(true);

    contactUs({
      ...formData,
      AdditionalFields: {
        position: "",
        company_website: "",
      },
    })
      .then(() => {
        setFormData(initialState);
        setLoading(false);
        CustomSnackbar.success("Message sent successfully.");
      })
      .catch((err) => {
        if (err?.response?.data && err?.response?.data[0]?.message) {
          CustomSnackbar.error(err?.response?.data[0]?.message);
        } else if (err?.message) {
          CustomSnackbar.error(err?.message);
        } else {
          CustomSnackbar.error("Error occurred, please try again later.");
        }
      })
      .finally(() => {
        window.grecaptcha.reset();
        setLoading(false);
        setFormData({ ...formData, captcha_value: "" });
      });
  };

  return (
    <React.Fragment>
      <section className='section'>
        <Container>
          <Row className='align-items-center mt-5'>
            <Col lg={6}>
              <div className='section-title mt-4 mt-lg-0'>
                <h3 className='title'>Get in touch</h3>
                <p className='text-muted'>{contactUsContent}</p>
                <Form
                  method='post'
                  className='contact-form mt-4'
                  name='myForm'
                  onSubmit={onSubmit}
                  id='myForm'
                >
                  <span id='error-msg'></span>
                  <Row>
                    <Col lg={12}>
                      <div className='mb-3'>
                        <Label htmlFor='name' className='form-label'>
                          Name
                        </Label>
                        <Input
                          type='text'
                          name='name'
                          id='name'
                          className='form-control'
                          placeholder='Enter your name'
                          value={formData.name}
                          onChange={onChangeValue}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='mb-3'>
                        <Label htmlFor='email_address' className='form-label'>
                          Email
                        </Label>
                        <Input
                          type='email'
                          className='form-control'
                          id='email'
                          name='email_address'
                          placeholder='Enter your email'
                          value={formData.email_address}
                          onChange={onChangeValue}
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className='mb-3'>
                        <Label htmlFor='contact_number' className='form-label'>
                          Mobile Number
                        </Label>
                        <Input
                          type='contact_number'
                          className='form-control'
                          id='contact_number'
                          name='contact_number'
                          placeholder='Enter your mobile number'
                          value={formData.contact_number}
                          onChange={onChangeValue}
                        />
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className='mb-3'>
                        <Label htmlFor='subject' className='form-label'>
                          Subject
                        </Label>
                        <Input
                          type='text'
                          className='form-control'
                          name='subject'
                          placeholder='Enter your subject'
                          value={formData.subject}
                          onChange={onChangeValue}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className='mb-3'>
                        <Label htmlFor='meassage' className='form-label'>
                          Your Message
                        </Label>
                        <textarea
                          className='form-control'
                          placeholder='Enter your message'
                          name='message'
                          rows='3'
                          value={formData.message}
                          onChange={onChangeValue}
                        ></textarea>
                      </div>
                    </Col>
                  </Row>

                  <div
                    className="d-flex justify-content-between gap-3 flex-wrap"
                  >
                    {captchaSiteKey && (
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        onErrored={(e) => {
                          console.log("e::", e);
                          CustomSnackbar.error("failed to load captcha");
                        }}
                        sitekey={captchaSiteKey}
                        onChange={onChange}
                      />
                    )}

                    <div className='text-end ml-auto'>
                      {loading ? (
                        <button
                          className='btn btn-primary disabled'
                          type='button'
                          id='submit'
                        >
                          Send Message <Spinner size='sm' />
                        </button>
                      ) : (
                        <button
                          className='btn btn-primary'
                          type='submit'
                          id='subscribebtn'
                        >
                          Send Message <i className='uil uil-message ms-1'></i>
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
            <Col lg={5} className='ms-auto order-first order-lg-last'>
              <div className='text-center'>
                <img src={contactImage} alt='' className='img-fluid' />
              </div>
              <div className='mt-4 pt-3'>
                <div className='d-flex text-muted align-items-center mt-2'>
                  <div className='flex-shrink-0 fs-22 text-primary'>
                    <i className='uil uil-map-marker'></i>
                  </div>
                  <div className='flex-grow-1 ms-2'>
                    <p className='mb-0'>{companyAddress}</p>
                  </div>
                </div>
                <div className='d-flex text-muted align-items-center mt-2'>
                  <div className='flex-shrink-0 fs-22 text-primary'>
                    <i className='uil uil-envelope'></i>
                  </div>
                  <div className='flex-grow-1 ms-2'>
                    <p className='mb-0'>{companyEmailAddress}</p>
                  </div>
                </div>
                <div className='d-flex text-muted align-items-center mt-2'>
                  <div className='flex-shrink-0 fs-22 text-primary'>
                    <i className='uil uil-phone-alt'></i>
                  </div>
                  <div className='flex-grow-1 ms-2'>
                    <p className='mb-0'>{companyContactNumber}</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <div className="map">
        <iframe
          title="maps"
          src={companyMapUrl}
          height="350"
          style={{ border: `0`, width: `100%` }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div> */}
    </React.Fragment>
  );
};

export default ContactContent;
