import classnames from "classnames";
import React from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row
} from "reactstrap";

const AccordionTabs = ({activeTab, setActiveTab}) => {
  const TabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row className='justify-content-center'>
      <Col lg={8}>
        <Nav
          className='faq-menu nav-fill nav-pills justify-content-center'
          id='pills-tab'
          role='tablist'
        >
          <NavItem>
            <NavLink
              to='#'
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                TabChange("1");
              }}
              type='button'
            >
              General
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to='#'
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                TabChange("2");
              }}
              type='button'
            >
              Buying
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to='#'
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                TabChange("3");
              }}
              type='button'
            >
              Payment
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => {
                TabChange("4");
              }}
              type='button'
            >
              Support
            </NavLink>
          </NavItem>
        </Nav>
      </Col>
    </Row>
  );
};

export default AccordionTabs;
