import React from "react";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import { getSettingByKey } from "../../helpers/common";
// import LanguageSwitch from "./LanguageSwitch";
import GooglePlayDownload from "../../assets/images/google-play-download.png";

const TopBar = () => {
  const settings = useSelector((state) => state.settings.data);

  // Social Icons
  const facebookLink = getSettingByKey(
    settings,
    "social_links_facebook"
  )?.value;
  const instaLink = getSettingByKey(settings, "social_links_instagram")?.value;
  const playStoreLink = getSettingByKey(
    settings,
    "social_links_play_store"
  )?.value;
  const linkedinLink = getSettingByKey(
    settings,
    "social_links_linkedin"
  )?.value;

  return (
    <React.Fragment>
      <div className='top-bar fixed-top sticky' style={{ zIndex: 1030 }}>
        <Container fluid className='custom-container'>
          <div className='g-5 align-items-center d-flex justify-content-between'>
            <div>
              <ul className='list-inline mb-0 text-center text-md-start'>
                <li className='list-inline-item'>
                  <ul className='topbar-social-menu list-inline mb-0'>
                    {facebookLink && (
                      <li className='list-inline-item'>
                        <a
                          href={facebookLink}
                          target='_blank'
                          className='social-link'
                          rel='noreferrer'
                        >
                          <i className='uil uil-facebook-f'></i>
                        </a>
                      </li>
                    )}

                    {linkedinLink && (
                      <li className='list-inline-item'>
                        <a
                          href={linkedinLink}
                          target='_blank'
                          className='social-link'
                          rel='noreferrer'
                        >
                          <i className='uil uil-linkedin-alt'></i>
                        </a>
                      </li>
                    )}

                    {instaLink && (
                      <li className='list-inline-item'>
                        <a
                          href={instaLink}
                          target='_blank'
                          className='social-link'
                          rel='noreferrer'
                        >
                          <i className='uil uil-instagram'></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>

            <div>
              <ul className='list-inline mb-0 d-flex justify-content-end gap-2'>
                {playStoreLink && (
                  <li className='list-inline-item'>
                    <a
                      href={playStoreLink}
                      target='_blank'
                      className='social-link text-dark hover-text'
                      rel='noreferrer'
                    >
                      <span className='download-app-text'>
                        Download Our App Now!
                      </span>{" "}
                      <img
                        src={GooglePlayDownload}
                        className='header-google-play-icon'
                        alt='GooglePlayDownload'
                      />
                    </a>
                  </li>
                )}
                {/* {!token &&
                  <li className="list-inline-item py-2 me-2 align-middle">
                    <span
                      onClick={openModal}
                      role="button"
                      className="text-dark fw-medium fs-13"
                    >
                      <i className="uil uil-lock"></i>
                      Sign Up
                    </span>

                    <SignUpModal modal={modal} openModal={openModal} />
                  </li>} */}

                {/* <li className="list-inline-item align-middle">
                  <LanguageSwitch />
                </li> */}
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TopBar;
