import React from "react";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { authKey_local_storage_key, redirect_url_key } from "../helpers/common";
import { CustomSnackbar } from "../helpers/CustomSnackbar";

export default function PrivateRoute({ path, children }) {
  const auth = localStorage.getItem(authKey_local_storage_key);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      setTimeout(() => {
        CustomSnackbar.error("You must be logged in to proceed!");
        if (path) {
          localStorage.setItem(redirect_url_key, path);
        }
        navigate("/signin");
      }, 100);
    }
  }, [auth, navigate, path]);

  return auth ? children : <Navigate to='/signin' />;
}
