
import {
  GET_CONTENTS_SUCCESS,
  GET_CONTENTS_FAILURE,
} from '../constants/actionTypes';

const INITIAL_STATE = {
  'partial_contents': [],
  'aboutus_key_features': [],
  'services': [],
  'team': [],
  'locations': [],
  'job_categories': [],
  'blogs': [],
  error: null,
  loading: true,
};

export default function contents(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CONTENTS_SUCCESS:
      return {...state, [action.payload.contentType]: action.payload.data, loading: false};
    case GET_CONTENTS_FAILURE:
      return {...state, loading: false}
    default:
      return state;
  }
}
