import React, { useState } from "react";

import { Col, Container, Input, Row } from "reactstrap";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getSettingByKey } from "../../../helpers/common";
import AccordionItem from "./AccordionItem.js";
import AccordionTabs from "./AccordionTabs";
import SmallLoader from "../../../helpers/Common/SmallLoader";

const FaqContent = () => {
  const settings = useSelector((state) => state.settings.data);
  const companyEmailAddress = getSettingByKey(
    settings,
    "company_email_address"
  )?.value;

  const [activeTab, setActiveTab] = useState("1");
  const [query, setQuery] = useState("");

  const { data: faqs, loading } = useSelector((state) => state.faqs);
  const filteredFaqs = faqs?.filter((item) =>
    item?.question?.toLowerCase()?.includes(query?.toLowerCase())
  );

  const renderFaqs = () => {
    if (loading) {
      return (
        <div className='mb-5'>
          <SmallLoader />
        </div>
      );
    }

    if (!filteredFaqs?.length) {
      return <p className='text-muted text-center mt-4'>No FAQs found.</p>;
    }

    return filteredFaqs?.map((item) => (
      <Col lg={12} key={item?.id}>
        <AccordionItem item={item} />
      </Col>
    ));
  };

  return (
    <React.Fragment>
      <section className='section'>
        <Container>
          <div className='d-none'>
            <AccordionTabs activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>

          <h4 className='text-center mb-3 mt-n3'>How can we help you?</h4>

          <div className='faq-search-box'>
            <i className='uil uil-search-alt fs-18'></i>
            <Input
              placeholder='Type keywords to find answers'
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>

          <Row className='align-items-center'>
            {renderFaqs()}

            <Col lg={12}>
              <div className='text-center mt-5'>
                <Link
                  to='/contact-us'
                  className='btn btn-primary btn-hover mt-2'
                >
                  <i className='uil uil-phone'></i> Contact Us
                </Link>
                <a
                  href={`mailto:${companyEmailAddress}`}
                  className='btn btn-warning btn-hover mt-2 ms-md-2'
                >
                  <i className='uil uil-envelope'></i> Email Now
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default FaqContent;
