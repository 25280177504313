import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Input, Spinner } from "reactstrap";
import { useDispatch } from "react-redux";
import { login } from "../../../actions/customer";
import { CustomSnackbar } from "../../../helpers/CustomSnackbar";
import PasswordInput from "../../../helpers/Common/PasswordInput";
import { isEmailVerified, redirect_url_key } from "../../../helpers/common";
import VerifyEmailModal from "./VerifyEmailModal";

const SignInForm = ({ formData, setFormData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openVerifyModal, setOpenVerifyModal] = useState(false);

  useEffect(() => {
    const email = localStorage.getItem("last_logged_in_email");

    if (email) {
      setFormData((prev) => ({ ...prev, email }));
    }
  }, [setFormData]);

  const onValueChange = (e, name) => {
    setFormData({ ...formData, [name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData?.email) {
      CustomSnackbar.warning("Please enter your email address");
      return;
    }

    if (formData?.password?.length < 8) {
      CustomSnackbar.warning("Password should be at least 8 characters");
      return;
    }

    localStorage.setItem("last_logged_in_email", formData?.email);
    localStorage.setItem("customer_type", formData.customer_type);

    const redirect_url = localStorage.getItem(redirect_url_key);

    setIsLoading(true);

    dispatch(login(formData)).then((res) => {
      setIsLoading(false);

      // If the user is not verified, then showing popup to verify there accounts first.
      if (res?.data && !isEmailVerified(res?.data)) {
        setOpenVerifyModal(true);
        return;
      }

      if (res?.data?.customer?.customer_type === formData?.customer_type) {
        if (redirect_url) {
          navigate(redirect_url);
          localStorage.removeItem(redirect_url_key);
          return false;
        }

        if (formData?.customer_type === 1) {
          navigate("/employer/company/manage");
        } else {
          navigate("/candidate/profile/update");
        }
      }
    });
  };

  return (
    <>
      <VerifyEmailModal
        openModal={openVerifyModal}
        setOpenModal={setOpenVerifyModal}
        formData={formData}
      />

      <Form onSubmit={onSubmit} className='auth-form'>
        <div className='mb-3'>
          <label htmlFor='emailInput' className='form-label'>
            Email
          </label>
          <Input
            type='email'
            className='form-control'
            id='emailInput'
            placeholder='Enter your email'
            required
            name='email'
            onChange={(e) => onValueChange(e, "email")}
            value={formData?.email}
          />
        </div>

        <div className='mb-3'>
          <PasswordInput
            label='Password'
            id='passwordInput'
            name='password'
            required={true}
            value={formData?.password}
            placeholder='Enter your password'
            onChange={(e) => onValueChange(e, "password")}
          />
        </div>
        <div className='mb-4'>
          <div className='form-check'>
            <Link to='/resetpassword' className='float-end text-white'>
              Forgot Password?
            </Link>
          </div>
        </div>
        <div className='text-center'>
          {isLoading ? (
            <button
              className='btn btn-white btn-hover w-100 disabled'
              type='button'
            >
              Sign In <Spinner size='sm' />
            </button>
          ) : (
            <button className='btn btn-white btn-hover w-100' type='submit'>
              Sign In
            </button>
          )}
        </div>
      </Form>
    </>
  );
};

export default SignInForm;
