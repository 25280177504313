import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import SignUpForm from "../../../pages/ExtraPages/SignUp/SignUpForm";
import VerifyEmail from "../../../pages/ExtraPages/SignUp/VerifyEmail";
import { authKey_local_storage_key } from "../../common";

const SignupPopup = () => {
  const [modalOpened, setModalOpened] = useState(false);

  const initial_state = {
    name: "",
    email: "",
    mobile_number: "",
    password: "",
    confirm_password: "",
    customer_type: 2,
  };
  const [formData, setFormData] = useState(initial_state);
  const [showEmailVerify, setShowVerifyEmail] = useState(false);

  const [tab, setTab] = React.useState(2);
  const changeTab = (tab) => {
    setTab(tab);
    setFormData({ ...formData, customer_type: tab });
  };

  const togglePopup = () => setModalOpened(!modalOpened);

  useEffect(() => {
    const email = localStorage.getItem("last_logged_in_email");
    const auth = localStorage.getItem(authKey_local_storage_key);

    if (!email && !auth) {
      setTimeout(() => {
        setModalOpened(true);
      }, 12000);
    }
  }, []);

  return (
    <div
      className='modal fade'
      id='applyNow'
      tabIndex='-1'
      aria-labelledby='applyNow'
      aria-hidden='true'
    >
      <Modal
        isOpen={modalOpened}
        toggle={togglePopup}
        centered
        className='signup-popup-dialog'
      >
        <ModalBody className='modal-body p-0 auth-box text-white pt-3'>
          <div className='position-absolute end-0 top-0 p-3'>
            <button
              type='button'
              className='btn-close text-white'
              data-bs-dismiss='modal'
              aria-label='Close'
              style={{ filter: "brightness(0) invert(1)" }}
              onClick={togglePopup}
            ></button>
          </div>

          {showEmailVerify ? (
            <div className='auth-content p-2 py-5 h-100'>
              <VerifyEmail
                formData={formData}
                onVerifyEmailSuccess={() => {
                  setModalOpened(false);
                  setShowVerifyEmail(false);
                }}
              />
            </div>
          ) : (
            <div className='auth-content p-4 h-100'>
              <div className='text-center'>
                <h5>Let's Get Started</h5>
                <p>
                  Sign Up and get access to all the features of Jobseekers Page
                </p>
              </div>

              <div className='tabs-sec innerpage-tabs-sec w-100 mt-2'>
                <div
                  className={`tab left-tab  ${tab === 2 && "active"}`}
                  onClick={() => changeTab(2)}
                >
                  <h6>Candidate</h6>
                  <h4>Sign Up as a Candidate</h4>
                </div>

                <div
                  className={`tab right-tab ${tab === 1 && "active"}`}
                  onClick={() => changeTab(1)}
                >
                  <h6>Employer</h6>
                  <h4>Sign Up as a Employer</h4>
                </div>
              </div>

              <SignUpForm
                formData={formData}
                setFormData={setFormData}
                setShowVerifyEmail={setShowVerifyEmail}
              />
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SignupPopup;
